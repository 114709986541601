import React from "react";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

const Reachability = () => {
  useRedirectHandler(() => {
    return "https://home.classdojo.com/#/story/?accountSettings=true";
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Reachability;
